import styles from './logo.module.css';
import { EColors } from '../../enums/colors';
export const Logo = () => {
  return (
    <div className={styles.logo}>
      <img
        src={'/sign-cropped.svg'}
        alt={'NPS logo'}
        className={styles.logoImage}
      />
      <p style={{ color: EColors.GRANITE_GRAY }} className={styles.logoText}>
        NPS
      </p>
    </div>
  );
};
