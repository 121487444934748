import React, { useEffect, useState } from 'react';
import { useLink } from '../../hooks/useLink';
import { IManager } from '../../types/Manager';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Item, LinksTable } from '../../components/LinksTable/LinksTable';
import styles from './polls-page.module.css';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { Modal } from '../../components/Modal/Modal';
import { Button, Checkbox, Form, Select } from 'antd';
import { EColors } from '../../enums/colors';

type FieldType = {
  forOrganizationId?: string;
  isMultiplyResponse?: boolean;
  managerId?: string;
  verticalId?: number;
};

export const PollsPage = () => {
  const me = useSelector((state: RootState) => state.me.data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const {
    links,
    getLinks,
    getOrganizations,
    organizations,
    createLink,
    updateLink,
    deleteLink,
    createQuestion,
    deleteQuestion,
    error,
    getVerticals,
    verticals,
    getQuestions,
    setError,
  } = useLink();

  const [managers, setManagers] = useState<IManager[] | undefined>([]);
  const [isMultiplyChecked, setIsMultiplyChecked] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      await getLinks();
      await getOrganizations();
      await getVerticals();
      await getQuestions();
    };
    if (me) {
      fetchData();
    }
  }, [me]);

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Опросы</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton onClick={handleOpenModal} text={'Cоздать опрос'} />
      </div>
      <LinksTable
        links={
          links?.map((link) => {
            return {
              url: `https://nps.artics.ru/${link.id}`,
              key: link.id,
              ...link,
            };
          }) as Item[]
        }
        verticals={verticals}
        organizations={organizations}
        deleteLink={deleteLink}
        updateLink={updateLink}
        createQuestion={createQuestion}
        deleteQuestion={deleteQuestion}
        error={error}
        isEditable={true}
        setError={setError}
      />
      <Modal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        className={styles.modal}
      >
        <p
          style={{
            fontSize: '28px',
            color: EColors.GRANITE_GRAY,
            textWrap: 'wrap',
            fontWeight: 700,
            lineHeight: '36px',
            fontFamily: 'Manrope',
          }}
        >
          Создание ссылки на опрос
        </p>
        <Form
          name="link"
          form={form}
          onFinish={async (values) => {
            await createLink({
              forOrganizationId: values.forOrganizationId.toString(),
              isMultiplyResponse: isMultiplyChecked,
              managerId: values.managerId,
              verticalId: values.verticalId,
            });
          }}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '10px',
            justifyContent: 'start',
          }}
        >
          <Form.Item<FieldType>
            name="forOrganizationId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Организация'}
              style={{ width: '283px' }}
              onChange={(value, option) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                setManagers(option.managers);
              }}
              options={organizations?.map((organization) => {
                return {
                  value: organization.id,
                  label: organization.title,
                  managers: organization.managers,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="managerId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Менеджер'}
              disabled={managers && managers?.length === 0}
              style={{ width: '283px' }}
              options={managers?.map((manager) => {
                return {
                  value: manager.id,
                  label: `${manager.surname} ${manager.name}`,
                }; // Assuming manager has a 'name' property
              })}
              filterOption={(input, option) =>
                (option?.label ?? '')?.includes(input)
              }
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="verticalId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Вертикаль'}
              style={{ width: '283px' }}
              options={verticals?.map((vertical) => {
                return {
                  value: vertical.id,
                  label: vertical.title,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Множественный опрос"
            name="isMultiplyResponse"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox
              style={{}}
              checked={isMultiplyChecked}
              onClick={() => setIsMultiplyChecked((prev) => !prev)}
            />
          </Form.Item>
          <Form.Item style={{ width: '100%' }}>
            <Button
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: 'Manrope',
              }}
              className={styles.button}
              type="primary"
              htmlType="submit"
            >
              Ок
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
