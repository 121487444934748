import ReactModal from 'react-modal';
import styles from '../AdminLayout/layout.module.css';
import React, { ReactNode } from 'react';
import '../../../App.css';

export interface IModalProps {
  children?: ReactNode;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  className?: string;
}
export const Modal = ({
  children,
  isModalOpen,
  handleCloseModal,
  className,
}: IModalProps) => {
  return (
    <ReactModal
      isOpen={isModalOpen}
      contentLabel="onRequestClose Example"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      className={className}
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(0,0,0, 0.70)',
          zIndex: 4000,
        },
      }}
    >
      <button onClick={handleCloseModal} className={styles.closeButton}>
        <img
          src={'/cross.png'}
          className={styles.crossImage}
          alt={'closeButton'}
        />
      </button>
      {children}
    </ReactModal>
  );
};
