import { EColors } from '../../enums/colors';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const AdminPlaceholder = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/admin/polls');
  }, [navigate]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <p
        style={{
          fontSize: '20px',
          color: EColors.DUSTY_GRAY,
          margin: 0,
        }}
      >
        Ничего не найдено
      </p>
    </div>
  );
};
