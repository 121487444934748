export enum EQuestion {
  ESTIMATE = 'ESTIMATE',
  COMMENT = 'COMMENT',
}

export type TQuestion = {
  text: string;
  order?: number | null;
  verticals?: number[];
  responseType: EQuestion;
};

export interface IQuestion {
  id: string;
  text: string;
  order?: number;
  questionToVerticals?: IQuestionToVerticals[];
  responseType: EQuestion;
}

export interface IQuestionToVerticals {
  id: string;
  verticalId: number;
  questionId: string;
  vertical: IVertical;
  question: IQuestion;
}

export interface IVertical {
  id: number;
  title: string;
  questionToVerticals?: IQuestionToVerticals[];
}

export interface ICreateQuestion {
  text: string;
  responseType: EQuestion;
}
