import React, { ReactNode } from 'react';
import { Content, Footer } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';

import {
  contentStyle,
  footerStyle,
  layoutStyle,
  siderStyle,
} from '../../styles/styles';
import { Logo } from '../Logo/Logo';
import { AdminNavigation } from '../AdminNavigation/AdminNavigation';
import { AdminInfo } from '../AdminInfo/AdminInfo';
import { Layout } from 'antd';
import styles from './layout.module.css';

export interface IAdminLayoutProps {
  children: ReactNode;
}
export const AdminLayout = ({ children }: IAdminLayoutProps) => {
  return (
    <>
      <Layout style={layoutStyle}>
        <Layout>
          <Sider width={'300px'} style={siderStyle}>
            <Logo />
            <AdminNavigation />
            <AdminInfo />
          </Sider>
          <Content style={contentStyle}>{children}</Content>
        </Layout>
        <Footer style={footerStyle}>
          <div className={styles.footerContainer}>
            <img src={'/logo.svg'} alt={'logo'} />
            <p style={{ fontSize: '15px', margin: 0 }}>© 2000 - 2024 Артикс</p>
          </div>
          <div
            onClick={() => {
              window.open('https://vk.com/articsru', '_blank');
            }}
            className={styles.socialsContainer}
          >
            <div
              onClick={() => {
                window.open('https://vk.com/articsru', '_blank');
              }}
              className={styles.social}
            >
              <img
                onClick={() => {
                  window.open('https://t.me/truedigitalclub', '_blank');
                }}
                src={'/telegram.svg'}
                alt={'telegram'}
              />
            </div>
            <div className={styles.social}>
              <img src={'/vk.svg'} alt={'vk'} />
            </div>
            <div className={styles.social}>
              <img src={'/trueDigital.svg'} alt={'vk'} />
            </div>
          </div>
        </Footer>
      </Layout>
    </>
  );
};
