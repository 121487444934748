import React, { useMemo, useState } from 'react';
import { Form, Input, InputNumber, Select, Table, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  EQuestion,
  IQuestion,
  IVertical,
  TQuestion,
} from '../../types/Question';

export interface Item extends IQuestion {
  key: string;
}

enum InputType {
  TEXT = 'text',
  VERTICAL = 'vertical',
  RESPONSE_TYPE = 'responseType',
  ORDER = 'order',
  DEFAULT = 'default',
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: InputType;
  record: Item;
  index: number;
  children: React.ReactNode;
}

interface IQuestionsTableProps {
  questions?: IQuestion[];
  deleteQuestion: (questionId: string) => Promise<boolean>;
  insertQuestionToVertical: (
    verticalId: number,
    questionId: string,
  ) => Promise<any>;
  removeQuestionToVertical: (
    verticalId: number,
    questionId: string,
  ) => Promise<any>;
  updateQuestion: (id: string, data: TQuestion) => Promise<void>;
  getQuestions: (verticalId?: number) => Promise<void>;
  verticals?: IVertical[];
  isEditable: boolean;
}

export const QuestionsTable = ({
  questions,
  deleteQuestion,
  updateQuestion,
  removeQuestionToVertical,
  insertQuestionToVertical,
  isEditable,
  verticals,
}: IQuestionsTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [responseType, setResponseType] = useState<EQuestion | undefined>(
    undefined,
  );
  const [order, setOrder] = useState<number>();
  const [text, setText] = useState<string | undefined>(undefined);
  const [updatedVerticals, setUpdatedVerticals] = useState<number[]>([]);

  const cancel = () => {
    setEditingKey('');
    setText(undefined);
    setOrder(undefined);
    setUpdatedVerticals([]);
  };

  const save = async (id: string) => {
    try {
      if (responseType && text)
        await updateQuestion(id, {
          responseType,
          text,
          order: order || null,
        });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ questionId: '', ...record });
    setEditingKey(record.key);
    setText(record.text);
    setOrder(record.order);
    setUpdatedVerticals(
      record.questionToVerticals?.map((item) => item.verticalId) || [],
    );
    setResponseType(record.responseType);
  };

  const columns = [
    {
      title: 'Вопрос',
      dataIndex: 'text',
      width: '50%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.text}</Typography.Text>;
      },
    },
    {
      title: 'тип',
      dataIndex: 'responseType',
      width: '10%',
      editable: true,
      render: (_: any, record: Item) => {
        return (
          <Typography.Text>
            {record.responseType === 'ESTIMATE' ? 'Оценка' : 'Комментарий'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'порядок',
      dataIndex: 'order',
      width: '10%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.order}</Typography.Text>;
      },
    },
    {
      title: 'Вертикали',
      dataIndex: 'vertical',
      width: '10%',
      editable: true,
      render: (_: any, record: Item) => {
        return (
          <div
            style={{
              display: 'flex',
              overflowY: 'scroll',
              height: '60px',
            }}
          >
            <Typography.Text>
              {record.questionToVerticals?.map((item) => {
                return (
                  <Typography.Text>
                    {item.vertical.title}
                    <br />
                  </Typography.Text>
                );
              })}
            </Typography.Text>
          </div>
        );
      },
    },
    // {
    // 	title: 'Удалить',
    // 	dataIndex: 'delete',
    // 	editable: false,
    // 	render: (_: any, question: IQuestion) =>
    // 				(<Typography.Link onClick={() => deleteQuestion(question.id)}>
    // 					Удалить
    // 				</Typography.Link>)
    //
    // },
  ];

  isEditable &&
    columns.push({
      title: 'Операции',
      dataIndex: 'operation',
      width: '25%',
      editable: false,
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Сохранить
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              <a>Отменить</a>
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              Изменить
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => deleteQuestion(record.id)}
            >
              Удалить
            </Typography.Link>
          </>
        );
      },
    });

  const isEditing = (record: Item) => record.key === editingKey;

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = useMemo(() => {
      switch (inputType) {
        case InputType.RESPONSE_TYPE:
          return (
            <Select
              showSearch
              defaultValue={record.responseType}
              style={{ width: '200px' }}
              value={responseType}
              options={[
                {
                  value: 'ESTIMATE',
                  label: 'Оценка',
                },
                {
                  value: 'COMMENT',
                  label: 'Комментарий',
                },
              ]}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          );
        case InputType.TEXT:
          return (
            <TextArea
              defaultValue={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          );
        case InputType.ORDER:
          return (
            <InputNumber
              defaultValue={order}
              onChange={(value) => {
                setOrder(value || undefined);
              }}
            />
          );
        case InputType.VERTICAL:
          return (
            <Select
              mode={'multiple'}
              showSearch
              style={{ width: '200px' }}
              onChange={(v) => {
                console.log();
                updatedVerticals?.length > v.length
                  ? removeQuestionToVertical(
                      updatedVerticals.filter((item) => !v.includes(item))[0],
                      record.id,
                    )
                  : insertQuestionToVertical(
                      v.filter((item) => !updatedVerticals.includes(item))[0],
                      record.id,
                    );
                setUpdatedVerticals(v);
              }}
              value={updatedVerticals}
              options={verticals?.map((vertical) => {
                return {
                  value: vertical.id,
                  label: vertical.title,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          );
        default:
          return <Input />;
      }
    }, [inputType, record, text]);

    return <td {...restProps}>{editing ? inputNode : children}</td>;
  };

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={questions?.map((question) => ({
          key: question.id,
          ...question,
        }))}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          style: {
            backgroundColor: 'white',
            margin: '0',
          },
        }}
      />
    </Form>
  );
};
