'use client';
import { useState } from 'react';
import axios from 'axios';
import { ICreateRole, IUser, IUserCore } from '../types/User';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

export const useUser = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const [userEmails, setUserEmails] = useState<IUserCore[] | undefined>(
    undefined,
  );
  const jwt = useSelector((state: RootState) => state.me.jwt);
  const getUsers = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/roles/`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setUsers(data);
    } catch (e) {}
  };

  const getUserEmails = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/users/`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setUserEmails(data.items);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteUserRole = async (userId: number) => {
    try {
      await axios.delete(`${apiUrl}/auth/${userId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await getUsers();
    } catch (e) {
      console.log(e);
    }
  };

  const createUserRole = async (data: ICreateRole) => {
    try {
      await axios.post(`${apiUrl}/auth/register`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await getUsers();
    } catch (e) {
      console.log(e);
    }
  };
  //
  // const authenticate = async (
  //   publicKey: string,
  //   privateKey: string,
  // ): Promise<boolean> => {
  //   try {
  //     const { data }: AxiosResponse = await axios.post(`${apiUrl}/auth/login`, {
  //       publicKey: publicKey,
  //       privateKey: privateKey,
  //     });
  //     if (data.accessToken) {
  //       localStorage.setItem('jwt', data.accessToken);
  //       localStorage.setItem('refreshToken', data.refreshToken);
  //       await fetchMe(data.accessToken);
  //       setJwt(data.accessToken);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     localStorage.clear();
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   if (jwt && !me && false) {
  //     fetchMe(jwt)
  //       .catch(() =>
  //         fetchRefreshToken(refreshToken!)
  //           .then((value: any) => {
  //             if (value) {
  //               setJwt(value.accessToken);
  //               localStorage.setItem('jwt', JSON.stringify(value.accessToken));
  //               localStorage.setItem(
  //                 'refreshToken',
  //                 JSON.stringify(value.refreshToken),
  //               );
  //               return fetchMe(jwt)
  //                 .then(() => {})
  //                 .catch(() => {
  //                   logout();
  //                 });
  //             } else {
  //               logout();
  //               localStorage.clear();
  //             }
  //           })
  //           .catch((e: any) => {
  //             console.log(e);
  //             logout();
  //           }),
  //       )
  //       .then(() => {
  //         setAuth(true);
  //       })
  //       .catch(() => {
  //         logout();
  //       });
  //   }
  // }, [jwt, me]);

  return {
    jwt,
    getUsers,
    users,
    getUserEmails,
    userEmails,
    createUserRole,
    deleteUserRole,
  };
};
