import React, { useState } from 'react';
import { Form, Table, Typography } from 'antd';
import { ERoles } from '../../enums/roles';
import { IUser } from '../../types/User';

export interface Item extends IUser {
  key: string;
}

interface IQuestionsTableProps {
  users?: IUser[];
  deleteUserRole: (userId: number) => Promise<void>;
  isEditable: boolean;
}

export const UsersTable = ({
  users,
  deleteUserRole,
  isEditable,
}: IQuestionsTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [role, setRole] = useState<ERoles | undefined>(undefined);

  const cancel = () => {
    setEditingKey('');
    setRole(undefined);
  };

  const save = async (id: number) => {
    try {
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ role: '', ...record });
    setEditingKey(record.key);
    setRole(record.role);
  };

  const columns = [
    {
      title: 'Почта',
      dataIndex: 'email',
      width: '40%',
      editable: false,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.user.email}</Typography.Text>;
      },
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      width: '40%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.role}</Typography.Text>;
      },
    },
    // {
    // 	title: 'Удалить',
    // 	dataIndex: 'delete',
    // 	editable: false,
    // 	render: (_: any, question: IQuestion) =>
    // 				(<Typography.Link onClick={() => deleteQuestion(question.id)}>
    // 					Удалить
    // 				</Typography.Link>)
    //
    // },
  ];

  isEditable &&
    columns.push({
      title: 'Операции',
      dataIndex: 'operation',
      width: '10%',
      editable: false,
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Сохранить
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              <a>Отменить</a>
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => deleteUserRole(record.userId)}
            >
              Удалить
            </Typography.Link>
          </>
        );
      },
    });

  const isEditing = (record: Item) => record.key === editingKey;

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={users?.map((user) => ({
          key: user.id.toString(),
          ...user,
        }))}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          style: {
            backgroundColor: 'white',
            margin: '0',
          },
        }}
      />
    </Form>
  );
};
