export const EColors = {
  CHARCOAL_GRAY: '#323232',
  SLATE_GRAY: '#3F444F',
  GRANITE_GRAY: '#6B6B6B',
  DUSTY_GRAY: '#807D7D',
  MEDIUM_GRAY: '#999999',
  LIGHT_GRAY: '#b7b5b5',
  PALE_GRAY: '#d9d9d9',
  WHITE: '#FFFFFF',
};
