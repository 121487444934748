export enum ERoutes {
  BASE = '/',
  ADMIN = '/admin',
  CLIENT = '/:linkId',
  SUCCESS = '/success',
  NOT_FOUND = '*',
}

export enum EAdminRoutes {
  BASE = '/',
  POLLS = '/polls',
  LOGIN = '/login',
  QUESTIONS = '/questions',
  USERS = '/users',
}

export const adminRoutes = [
  {
    path: `${ERoutes.ADMIN}${EAdminRoutes.POLLS}`,
    name: 'Опросы',
    isAdmin: false,
  },
  {
    path: `${ERoutes.ADMIN}${EAdminRoutes.QUESTIONS}`,
    name: 'Вопросы',
    isAdmin: false,
  },
  {
    path: `${ERoutes.ADMIN}${EAdminRoutes.USERS}`,
    name: 'Пользователи',
    isAdmin: true,
  },
];
