import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './admin-info.module.css';
import { EColors } from '../../enums/colors';
import { logout } from '../../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

export const AdminInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const me = useSelector((state: RootState) => state.me.data);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <>
      {me && (
        <div className={styles.container}>
          <p
            style={{
              color: EColors.MEDIUM_GRAY,
              textAlign: 'start',
              lineHeight: '30px',
            }}
          >
            {me.name} {me.surname}
            <br />
            {me.email}
          </p>
          <a
            style={{
              fontSize: '20px',
              color: EColors.GRANITE_GRAY,
              textAlign: 'start',
              lineHeight: '30px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleLogout}
          >
            Выйти
          </a>
        </div>
      )}
    </>
  );
};
